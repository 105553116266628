import ThirdPartyForm from '../third_party_form';
import I18n from '../../../i18n';
import helpers from '../../../utils/helpers';
import * as bootstrap from 'bootstrap';

const Result = function(resource, element, data, resourceData) {
  const quiz = resource.getQuiz();
  const fieldHelpers = quiz.fieldHelpers;
  const nodeId = resource.nodeId || resource.currentNodeId;
  const mainForm = quiz.mainForm;
  const optin = data.optin || {};
  const conversionEvent = optin.conversion_event || {};
  let conversionEventId = conversionEvent.value || null;
  const settings = mainForm.input.settings;

  settings.attached_conversions = settings.attached_conversions || [];

  const attachedConversions = settings.attached_conversions;

  this.data = data;

  this.attachEvents = function() {
    const actions = element.querySelector('.post-action');
    const resultActions = element.querySelector('.result-action');
    const questionActions = element.querySelector('.question-actions');
    const addQuestionPath = questionActions.querySelector('.add-question-path');
    const resultText = element.querySelector('.result-text');
    const resultVideo = element.querySelector('.result-video');
    let fieldChecks = element.querySelectorAll(
      `.optin-actions .fields input[name="fields"], .fields input[name="fields-required"]`,
    );
    let requiredFieldChecks = element.querySelectorAll(
      '.fields input[name="fields-required"]',
    );
    const nextStepChecks = element.querySelectorAll('.next-step-check');
    const privacyPolicyChecks = element.querySelectorAll('.privacy-check');
    const urlTargetChecks = element.querySelectorAll('.url-target-check');
    const privacyUrlEl = element.querySelector('input[name=privacy_url]');
    const nextStepUrlEl = element.querySelector('input[name=url]');
    const thankYouTextEl = element.querySelector('input[name=text_message]');
    const conversionEventEl = element.querySelector('select[name=conversion_event]');
    const resultButtonUrlInput = element.querySelector('input[name=button_url]');
    const resultButtonTextInput = element.querySelector('input[name=button_text]');
    const optinButtonTextInput = element.querySelector('input[name=optin_button_text]');

    conversionEventEl.addEventListener('change', (e) => {
      data.optin = data.optin || {};
      const id = e.target.value;

      const prevIdx = attachedConversions.findIndex(
        (idx) => idx === Number(conversionEventId),
      );
      if (prevIdx > -1) {
        attachedConversions.splice(prevIdx, 1);
      }

      if (id) {
        data.optin.conversion_event = {
          value: id,
        };

        attachedConversions.push(id);
      } else {
        data.optin.conversion_event = {
          value: '',
        };
      }

      conversionEventId = id;
    });

    resultText.addEventListener('change', (e) => {
      data.text = e.target.value;
    });

    resultVideo.addEventListener('change', (e) => {
      data.video = e.target.value;
    });

    actions.addEventListener('change', (e) =>  {
      resourceData.action = e.target.value;
      this.triggerEvents();
      quiz.reloadTree(nodeId);
    });

    resultActions.addEventListener('change', (e) =>  {
      resourceData.result_action = e.target.value;
      this.triggerEvents();
      quiz.reloadTree(nodeId);
    });

    fieldChecks.forEach((check) => {
      check.addEventListener('change', () => {
        this.updateOptinFieldsValues();
      });
    });

    requiredFieldChecks.forEach((check) => {
      check.addEventListener('change', (e) => {
        const checked = e.target.checked;
        const field = e.target.dataset.field;
        const fieldCheck = element.querySelector(`.form-check-input[data-field=${field}]`);

        if (checked) {
          fieldCheck.checked = true;
        }
      });
    });

    nextStepChecks.forEach((el) => {
      el.addEventListener('change', (e) => {
        e.preventDefault();
        this.loadOptinNextStep(e.target.value);
      });
    });

    privacyPolicyChecks.forEach((el) => {
      el.addEventListener('change', (e) => {
        e.preventDefault();
        this.loadOptinPrivacyPolicy(e.target.value);
      });
    });

    urlTargetChecks.forEach((el) => {
      el.addEventListener('change', (e) => {
        e.preventDefault();
        data.optin.url_target = e.target.value;
      });
    });

    privacyUrlEl.addEventListener('change', (e) => {
      e.preventDefault();
      data.optin.privacy_url = e.target.value;
    });

    nextStepUrlEl.addEventListener('change', (e) => {
      e.preventDefault();
      data.optin.next_step_url = e.target.value;
    });

    thankYouTextEl.addEventListener('change', (e) => {
      e.preventDefault();
      data.optin.thank_you_text = e.target.value;
    });

    resultButtonUrlInput.addEventListener('change', (e) => {
      e.preventDefault();
      data.button.url = e.target.value;
    });

    resultButtonTextInput.addEventListener('change', (e) => {
      e.preventDefault();
      data.button.text = e.target.value;
    });

    optinButtonTextInput.addEventListener('change', (e) => {
      e.preventDefault();
      data.optin.button_text = e.target.value;
    });

    if (addQuestionPath) {
      addQuestionPath.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        quiz.loadQuestionPath(resourceData);
      });
    }
  };

  this.getResourceType = function() {
    return typeof(resource.loadQuestion) === 'function' ? 'question' : 'answer';
  };

  this.triggerEvents = function() {
    const postSelect = element.querySelector('.post-action');
    const postAction = resourceData.action;

    switch(postAction) {
      case 'show-question-path':
        this.showQuestionPath();
        break;
      case 'show-result':
        this.showResultActions();
        this.showOptinMethods();
        break;
      default:
        postSelect.value = '';
        this.hideAnswerActions();
    }
  };

  this.hideAnswerActions = function() {
    element.querySelector('.question-actions').classList.add('d-none');
    element.querySelector('.result-actions').classList.add('d-none');
    element.querySelector('.optin-actions').classList.add('d-none');
    element.querySelector('.result-button-actions').classList.add('d-none');
  };

  this.loadHtmlForm = function() {
    const result = this.data;
    const optin = result.optin || {};
    const formData = optin.form || {};
    const formHtml = formData.html;
    const formResponse = formData.data;
    const formContainer = element.querySelector('.embedded-form');
    const config = {
      selector: '',
      element: formContainer,
      html: formHtml,
      currentResponse: formResponse,
      callbacks: {
        successCb: (response, html) => {
          if (response.action) {
            data.optin.method = 'external_form';
            data.optin.form = {
              html: html,
              data: response,
            };
            this.buildMatchedInputs();
          }
        },
        errorCb: null,
        onReset: () => {
          const optin = result.optin;
          if (optin && optin.form) {
            delete optin.form;
          }
          this.clearMatchedInputs();
        },
      },
    };

    this.embedForm = new ThirdPartyForm(config);
    this.embedForm.load();
  };

  this.updateOptinFieldsValues = function() {
    let optinFields = element.querySelectorAll('.optin-list .fields .optin-input');
    const fields = data.optin.fields;

    const updatedValues = [];
    optinFields.forEach((input, i) => {
      const fieldName = input.value;
      const isRequired = element.querySelector(`input[value=${fieldName}-required]:checked`);

      const field = {
        required: isRequired ? true : false,
        type: 'text',
        label: input.dataset.label,
        placeholder: input.dataset.placeholder,
        name: input.value,
        checked: input.checked,
        order: i,
      };

      if (input.checked) {
        updatedValues.push(field);
      }
    });

    fields.values = updatedValues;
  };

  this.loadOptinFields = function() {
    const optin = data.optin;
    const fields = optin.fields;
    const values = fields.values;
    const action = mainForm.action;
    const checkboxes = element.querySelectorAll('.optin-list .fields .optin-input');
    const preChecked = values.map((field) => {
      return {
        name: field.name,
        required: field.required,
      };
    });

    if (action === 'new' && preChecked.length === 0) {
      checkboxes.forEach((input, i) => {
        const requiredId = `input.optin-required[data-field=${input.value}]`;
        const requiredCheck = element.querySelector(requiredId);
        input.checked = true;
        requiredCheck.checked = true;
      });
    } else {
      checkboxes.forEach((input, i) => {
        const requiredId = `input.optin-required[data-field=${input.value}]`;
        const requiredCheck = element.querySelector(requiredId);
        const checked = preChecked.find((el) => el.name === input.value);
        const preRequired = (checked && checked.required) || false;

        input.checked = checked ? true : false;
        requiredCheck.checked = preRequired;
      });
    }
  };

  this.loadOptinNextStep = function(value) {
    let input;
    const optin = data.optin;
    optin.next_step = value || optin.next_step || 'go_to_url';
    optin.thank_you_text = optin.thank_you_text ||
                           I18n.t('lead_tools.quiz.result.optin.thank_you');
    optin.next_step_url = optin.next_step_url ||
                          I18n.t('lead_tools.quiz.result.optin.url');
    optin.button_text = optin.button_text || I18n.t('lead_tools.quiz.result.button.text');
    optin.url_target = optin.url_target || '_self';

    const nextStep = optin.next_step;
    const urlTarget = optin.url_target;
    const nextStepCheckEl = element.querySelector(`.next-step-check[data-value=${nextStep}]`);
    const urlTargetCheckEl = element.querySelector(`.url-target-check[data-value=${urlTarget}]`);
    const urlTextEl = element.querySelector('.url-text');
    const thankYouTextEl = element.querySelector('.thank-you-text');
    const urlTargetContainer = element.querySelector('.url-target');
    const buttonTextInput = element.querySelector('input[name=optin_button_text]');

    nextStepCheckEl.checked = true;
    urlTargetCheckEl.checked = true;
    buttonTextInput.value = optin.button_text;

    switch(nextStep) {
      case 'go_to_url':
        input = urlTextEl.querySelector('input');
        input.value = optin.next_step_url;
        urlTextEl.classList.remove('d-none');
        urlTargetContainer.classList.remove('d-none');
        thankYouTextEl.classList.add('d-none');
        break;
      case 'thank_you_text':
        input = thankYouTextEl.querySelector('input');
        input.value = optin.thank_you_text;
        urlTextEl.classList.add('d-none');
        urlTargetContainer.classList.add('d-none');
        thankYouTextEl.classList.remove('d-none');
        break;
    }
  };

  this.loadOptinPrivacyPolicy = function(value) {
    const optin = data.optin;
    optin.privacy_policy = value || optin.privacy_policy || 'hide';
    optin.privacy_url = optin.privacy_url || '/privacy.html';

    const privacyPolicy = optin.privacy_policy;
    const privacyUrlEl = element.querySelector('.privacy-url');
    const input = privacyUrlEl.querySelector('input');
    const privacyCheck = element.querySelector(`.privacy-check[data-value=${privacyPolicy}`);

    input.value = optin.privacy_url;
    privacyCheck.checked = true;

    switch(privacyPolicy) {
      case 'show':
        privacyUrlEl.classList.remove('d-none');
        break;
      case 'hide':
        privacyUrlEl.classList.add('d-none');
        break;
    }
  };

  this.loadOptinList = function() {
    const action = mainForm.action;
    const optinList = element.querySelector('.optin-processes');
    const optinProcesses = mainForm.optinProcesses;
    const result = this.data;
    const optin = result.optin;

    if (optinProcesses.length > 0) {
      optinList.classList.remove('d-none');
    }

    helpers.empty(optinList);
    optinProcesses.forEach((optinProcess) => {
      const option = document.createElement('option');
      option.value = optinProcess.id;
      option.textContent = optinProcess.name;
      optinList.appendChild(option);
    });

    optinList.addEventListener('change', (e) => {
      optin.optin_process_id = e.target.value;
    });

    let selectedOptinId;
    const defaultOptin = optinProcesses.filter((optin) => optin.default)[0];
    const defaultOptinId = defaultOptin
      ? defaultOptin.id
      : (optinProcesses.length > 0 ? optinProcesses[0].id : '');

    if (optin.optin_process_id) {
      const preselectedOptin = optinProcesses
        .find((optinItem) => optinItem.id === optin.optin_process_id);
      selectedOptinId = preselectedOptin && preselectedOptin.id;
    }

    optinList.value = selectedOptinId || defaultOptinId;

    if (action === 'new' && optinProcesses.length > 0) {
      optin.optin_process_id = optinList.value;
    }
  };

  this.showQuestionPath = function() {
    const questions = resourceData.questions || [];
    const questionActionsEl = element.querySelector('.question-actions');
    const titleEl = questionActionsEl.querySelector('.title');
    const resultActionsEl = element.querySelector('.result-actions');

    questionActionsEl.classList.remove('d-none');
    resultActionsEl.classList.add('d-none');

    this.hideOptinMethods();

    if (questions.length > 0) {
      titleEl.textContent = I18n.t('lead_tools.quiz.question_path.follow');
    } else {
      titleEl.textContent = I18n.t('lead_tools.quiz.question_path.create');
    }
  };

  this.showResultActions = function() {
    element.querySelector('.question-actions').classList.add('d-none');
    element.querySelector('.result-actions').classList.remove('d-none');
  };

  this.showOptinMethods = function() {
    const resultAction = resourceData.result_action || '';
    const result = this.data;
    const optin = result.optin;
    const method = (optin && optin.method) || 'list';

    switch (resultAction) {
      case 'show-optin-methods':
        element.querySelector('.optin-actions').classList.remove('d-none');
        element.querySelector('.result-button-actions').classList.add('d-none');
        break;
      case 'show-result-button':
        element.querySelector('.optin-actions').classList.add('d-none');
        element.querySelector('.result-button-actions').classList.remove('d-none');
        break;
      default:
        element.querySelector('.optin-actions').classList.add('d-none');
        element.querySelector('.result-button-actions').classList.add('d-none');
    }

    if (resultAction === 'show-optin-methods') {
      switch(method) {
        case 'list':
          this.showOptinList();
          break;
        case 'external_form':
          this.showHtmlForm();
          break;
        default:
          this.showOptinList();
      }
    }
  };

  this.hideOptinMethods = function() {
    element.querySelector('.optin-actions').classList.add('d-none');
    element.querySelector('.result-button-actions').classList.add('d-none');
  };

  this.showNoAnswerActions = function() {
    element.querySelector('.question-actions').classList.add('d-none');
    element.querySelector('.result-actions').classList.add('d-none');
    element.querySelector('.optin-actions').classList.add('d-none');
    element.querySelector('.result-button-actions').classList.add('d-none');
  };

  this.loadResultButtonOptions = function() {
    const resultButtonUrlInput = element.querySelector('input[name=button_url]');
    const resultButtonTextInput = element.querySelector('input[name=button_text]');

    data.button = data.button || {};
    data.button.url = fieldHelpers.getValue(data.button, 'url') ||
                      I18n.t('lead_tools.quiz.result.button.url');
    data.button.text = fieldHelpers.getValue(data.button, 'text') ||
                       I18n.t('lead_tools.quiz.result.button.text');
    resultButtonUrlInput.value = data.button.url;
    resultButtonTextInput.value = data.button.text;
  }

  this.loadValues = function() {
    const resultText = element.querySelector('.result-text');
    const resultVideo = element.querySelector('.result-video');
    const postSelect = element.querySelector('.post-action');
    const resultSelect = element.querySelector('.result-action');
    const conversionEvent = element.querySelector('select[name=conversion_event]');
    const postAction = resourceData.action;
    const resultAction = resourceData.result_action;

    data.optin = data.optin || {};
    data.optin.conversion_event = data.optin.conversion_event || {};
    data.optin.conversion_event.value = data.optin.conversion_event.value || '';

    const rText = fieldHelpers.getValue(data, 'text');
    data.text = rText == undefined
      ? I18n.t('lead_tools.quiz.result.default_text')
      : rText;

    conversionEvent.value = data.optin.conversion_event.value;
    resultText.value = data.text;
    resultVideo.value = data.video || '';
    postSelect.value = postAction;
    resultSelect.value = ['show-optin-methods', 'show-result-button'].includes(resultAction) ? resultAction : '';

    if (this.getResourceType() === 'question') {
      postSelect.parentNode.style.display = 'none';
    }
  };

  this.load = function() {
    this.initValues();
    this.loadValues();
    this.attachEvents();
    this.loadOptinOptions();
    this.loadResultButtonOptions();
    this.triggerEvents();
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    popoverTriggerList.forEach(function (popoverTriggerEl) {
      new bootstrap.Popover(popoverTriggerEl);
    });
  };

  this.initValues = function() {
    data.uuid = data.uuid || helpers.uuidv4();
    data.optin = data.optin || {};
    data.button = data.button || {};

    const optin = data.optin;
    optin.fields = optin.fields || {};

    const fields = optin.fields;
    fields.values = fields.values || [];
  };

  this.loadOptinOptions = function() {
    this.loadHtmlForm();
    this.loadOptinList();
    this.loadOptinFields();
    this.updateOptinFieldsValues();
    this.loadOptinNextStep();
    this.loadOptinPrivacyPolicy();
  };

  this.showHtmlForm = function() {
    const result = this.data;
    result.optin = result.optin || {};
    const optin = result.optin;
    optin.method = 'external_form';

    this.buildMatchedInputs();
    const htmlFormTab = element.querySelector('.html-form-tab');
    if (htmlFormTab) htmlFormTab.click();
  };

  this.showOptinList = function() {
    const result = this.data;
    result.optin = result.optin || {};
    const optin = result.optin;
    optin.method = 'list';

    this.clearMatchedInputs();
    const optinTab = element.querySelector('.optin-tab');
    if (optinTab) optinTab.click();
  };

  this.buildMatchedInputs = function() {
    const originalInputs = this.originalFormInputs();
    const optin = this.data.optin || {};
    const matchedInputsContainer = element.querySelector(
      '.embedded-form-matched-inputs',
    );
    let embeddedFormInputs = [];

    if (optin.method === 'external_form') {
      embeddedFormInputs = optin.form.data.inputs.normal;
    }

    if (embeddedFormInputs.length === 0) return;

    if (matchedInputsContainer.children.length > 0) {
      this.clearMatchedInputs();
    }

    matchedInputsContainer.innerHTML = `
      <p class="font-weight-bold mb-2">
        ${I18n.t('lead_tools.third_party_form.matched_inputs_description')}
      </p>
    `;

    for (let i = 0; i < embeddedFormInputs.length; i++) {
      const input = embeddedFormInputs[i];
      const label = document.createElement('label');
      label.classList.add('form-label');
      label.textContent = input.name;

      const inputDropdown = document.createElement('select');
      inputDropdown.classList.add('form-control', 'mb-2');
      inputDropdown.name = input.name;
      inputDropdown.addEventListener('change', (e) => {
        input.original_name = e.target.value;
      });

      for (let j = 0; j < originalInputs.length; j++) {
        const origInput = originalInputs[j];
        const option = document.createElement('option');
        option.value = origInput.value;
        option.textContent = origInput.name;

        inputDropdown.appendChild(option);

        if (origInput.value.indexOf('email') > -1 &&
            inputDropdown.name.indexOf('mail') > -1) {
          option.selected = true;
          inputDropdown.dispatchEvent(new CustomEvent('change'));
        }
      }

      inputDropdown.value = input.original_name || null;

      let selectContainer = document.createElement('div');
      selectContainer.appendChild(label);
      selectContainer.appendChild(inputDropdown);

      matchedInputsContainer.appendChild(selectContainer);
    }
  };

  this.clearMatchedInputs = function() {
    const matchedInputsContainer = element.querySelector(
      '.embedded-form-matched-inputs',
    );
    matchedInputsContainer.innerHTML = '';
  };

  this.originalFormInputs = function() {
    return [
      { value: 'email', name: I18n.t('lead_tools.input_names.email') },
      { value: 'first_name', name: I18n.t('lead_tools.input_names.first_name') },
      { value: 'last_name', name: I18n.t('lead_tools.input_names.last_name') },
      { value: 'phone', name: I18n.t('lead_tools.input_names.phone') },
      { value: 'city', name: I18n.t('lead_tools.input_names.city') },
      { value: 'country', name: I18n.t('lead_tools.input_names.country') },
      { value: 'street', name: I18n.t('lead_tools.input_names.street') },
      { value: 'street_number', name: I18n.t('lead_tools.input_names.street_number') },
      { value: 'post_code', name: I18n.t('lead_tools.input_names.post_code') },
      { value: 'voucher_code', name: I18n.t('lead_tools.input_names.voucher_code') },
      { value: 'custom', name: I18n.t('lead_tools.input_names.custom') },
    ];
  };
};

export default Result;